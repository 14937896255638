import $axios from '../plugins/axios'

class ZendeskTicketsService {
  postZendeskComment(id, data) {
    return $axios.post(`/zendesk-tickets/${id}/comments`, data).then((res) => {
      return res.data;
    });
  }

  getTicketComments(id) {
    return $axios.get(`/zendesk-tickets/${id}/comments`).then((res) => {
      return res.data;
    });
  }

  getMyZendeskTickets() {
    return $axios.get(`/zendesk-tickets/my`).then((res) => {
      return res.data;
    });
  }
}

export default  new ZendeskTicketsService()