<template>
  <v-dialog width="800" v-model="showDialogComputed">
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
      <v-card  class="pa-4" elevation="8">
        <div class="pa-2 text-center">
          <h4 class="text-center">Please provide title and description.</h4>
          <div class="mb-5 d-flex flex-column px-8">
            <v-text-field autofocus v-model="title"></v-text-field>
            <v-textarea label="Description" outlined v-model="comment"></v-textarea>
          </div>

          <div>
            <v-btn @click="close" class="ml-5" style="height: 40px; width: 15%" color="#93332C">
              <span style="color: #ffffff">Close</span>
            </v-btn>
            <v-btn @click="save" class="ml-5" style="height: 40px; width: 15%" color="#263B4F">
              <span style="color: #ffffff">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
</template>
<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "NewTicketDialog",
  data: () => ({
    title: "",
    comment: "",
    loading: false,
  }),
  props: {
    clientId: {
      type: String,
      required: true,
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showDialogComputed: {
      get: function () {
        return this.showDialog;
      },
      set: function (value) {
        this.$emit("showDialogChange", value);
      },
    },
  },
  methods: {
    save() {
      this.loading = true
      const model = {
        subject: this.title,
        comment: this.comment,
      };
      ClientsService.postZendeskTicket(this.clientId, model).then(() => {
        this.$emit("closeDialog");
        this.$emit("updateNewTicketAdded");
        this.$func.popMessage("Ticket created successfully", "success");
      }).finally(()=>{
        this.loading = false
      })
    },
    close() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style scoped></style>
