<template>
  <v-dialog scrollable width="800" v-model="showDialogComputed">
    <v-overlay :value="loading">
      <v-progress-circular :size="70" :width="7" color="white" indeterminate></v-progress-circular>
    </v-overlay>
    <v-card style="height: 100%" class="pa-4" elevation="8">
      <div class="pa-2 text-center">
        <div class="mb-5 d-flex justify-end flex-row px-8">
          <h5 class="mr-8 lowExposure">Issued by: {{ ticket.requester }} </h5>
          <h5 class="lowExposure">Issued at: {{ ticket.created_at }} </h5>
        </div>
        <h2>{{ ticket.subject }}</h2>
        <p class="text-start">{{ ticket.description }}</p>
        <v-divider class="mb-4"></v-divider>
        <v-progress-circular class="py-10" v-if="loadingComments" :size="40" :width="5" color="primary"
                             indeterminate></v-progress-circular>

        <div v-for="com in comments">
          <div class="d-flex align-start flex-column">
            <div class="d-flex flex-md-row flex-column align-md-center align-start ">
              <div class="d-flex align-center">
                <v-icon class="mr-3"> mdi-account-circle</v-icon>
                <h5>{{ com.author }}&#160</h5>
                <h5 style="opacity: 0.7">/ {{ com.author_email }} &#160</h5>
              </div>
              <h5 style="opacity: 0.7">{{ com.created_at }}</h5>
            </div>
            <p class="text-start ml-2 pt-3">{{ com.body }}</p>
          </div>
          <v-divider class="my-2"></v-divider>
        </div>

        <v-textarea label="Comment" outlined v-model="comment"></v-textarea>

        <div class="d-flex justify-space-between">
          <v-btn @click="close" style="height: 40px; " color="#93332C">
            <span style="color: #ffffff">Nevermind</span>
          </v-btn>
          <v-btn class="ml-5" @click="save" style="height: 40px;" color="#263B4F">
            <span style="color: #ffffff">Post Comment</span>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import ClientsService from "@/services/clients.service";
import ZendeskTicketsService from "@/services/zendesk-tickets.service";

export default {
  name: "SIngleTicketDialog",
  data: () => ({
    title: "",
    comment: "",
    comments: [],
    loadingComments: false,
    loading: false,
  }),
  props: {
    clientId: {
      type: String,
      required: true,
    },
    ticket: {
      type: Object,
      required: true,
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
    isAttorney: {
      type: Boolean,
      required: true,
    },
  },
  created() {

  },
  methods: {
    formatDate(item) {
      let date = new Date(item)
      let createdDate = date.getDate()
      let month = date.getMonth()
      let year = date.getFullYear()
      return `${month}/${createdDate}/${year}@${date.getHours()}:${date.getMinutes()}`
    },
    getComments() {
      this.loadingComments = true
      if (this.isAttorney) {
        ZendeskTicketsService.getTicketComments(this.ticket.id).then(res => {
          this.comments = res
        }).finally(res => {
          this.loadingComments = false
        })
      } else {
        ClientsService.getTicketComments(this.clientId, this.ticket.id).then(res => {
          this.comments = res
        }).finally(res => {
          this.loadingComments = false
        })
      }

    },
    save() {
      const model = {
        "body": this.comment
      }
      this.loading = true

      if (this.isAttorney) {
        ZendeskTicketsService.postZendeskComment(this.ticket.id, model).then(res => {
          this.$emit("closeDialog");
          this.$func.popMessage("Comment added successfully", "success");
        }).finally(() => {
          this.loading = false
        })
      } else {
        ClientsService.postZendeskComment(this.ticket.id, model).then(res => {
          this.$emit("closeDialog");
          this.$func.popMessage("Comment added successfully", "success");
        }).finally(() => {
          this.loading = false
        })
      }
    },
    close() {
      this.$emit("closeDialog");
    },
  },
  computed: {
    showDialogComputed: {
      get: function () {
        if (this.showDialog == true) this.getComments()
        return this.showDialog;
      },
      set: function (value) {
        this.$emit("showDialogChange", value);
      },
    },
  },
}
</script>

<style scoped>
.lowExposure {
  opacity: 0.6
}

</style>
